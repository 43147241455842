<template>
  <div class="uk-container">
    <form
        @submit.prevent
        ref="signUp"
        class="uk-grid-small"
        uk-grid
        autocomplete="off"
        action="#"
    >
      <div class="uk-width-1-1@s">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <IconFingerprint class="uk-form-icon uk-icon"></IconFingerprint>
            <input
                class="uk-input"
                type="text"
                name="username"
                placeholder="Username"
                autocomplete="off"
            >
          </div>
        </div>
      </div>

      <div class="uk-width-1-1">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <IconEmail class="uk-form-icon uk-icon"></IconEmail>
            <input
                class="uk-input"
                type="text"
                name="email"
                placeholder="Email"
                autocomplete="off"
            >
          </div>
        </div>
      </div>
      <div class="uk-width-1-1@s">
        <hr>
      </div>
      <div class="uk-width-1-1">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <IconLock class="uk-form-icon uk-icon"></IconLock>
            <input
                class="uk-input"
                name="passphrase"
                type="password"
                placeholder="Passphrase"
                autocomplete="new-password"
            >
          </div>
        </div>
      </div>

      <div class="uk-width-1-1">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <IconLock class="uk-form-icon uk-icon"></IconLock>
            <input
                class="uk-input"
                name="confirmedPassphrase"
                type="password"
                placeholder="Confirm Passphrase"
                autocomplete="new-password"
            >
          </div>
        </div>
      </div>

      <div class="uk-width-1-1">
        <button
            class="uk-button uk-button-primary uk-width-1-1"
            @click="register"
        >
          <span class="icon is-medium">
            <IconCardAccountDetails/>
          </span>
          Register
        </button>
      </div>
    </form>

  </div>
</template>

<script>
import * as actions from "../../../store/graph/operations"
import {popup} from "@/components/use/popups/popupMessages"

export default {
  name: "Register",
  props: {},
  methods: {
    register() {
      const form = this.$refs.signUp
      const credentials = {
        username: form.username.value,
        email: form.email.value,
        passphrase: form.passphrase.value,
        confirmedPassphrase: form.confirmedPassphrase.value,
      }

      const missingUsername = !credentials.username
      if (missingUsername) return this.popInfo('a unique username is required to sign up')

      const missingEmail = !credentials.email
      if (missingEmail) return this.popInfo('please supply your email to sign up')

      const missingPassword = !credentials.passphrase
      if (missingPassword) return this.popInfo('a passphrase is required to sign up')

      const missingPassphraseConfirmation = !credentials.confirmedPassphrase
      if (missingPassphraseConfirmation) return this.popInfo('please repeat your passphrase to sign up')

      const passphrasesAreDifferent = credentials.passphrase !== credentials.confirmedPassphrase
      if (passphrasesAreDifferent) return this.popWarning('your passphrases do not match')

      this.$store.dispatch(actions.REGISTER_USER, {
        username: form.username.value,
        email: form.email.value,
        passphrase: form.passphrase.value
      })
          .then(result => {
            const message = result?.message
            message.timeout = 0
            if (message) popup(message)
            this.$router.push('/')
          })
    }
  }
}
</script>

<style scoped>
</style>
