<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          Page not found
        </h1>
        <h2 class="subtitle">
          404
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "PageNotFound"
  }
</script>

<style scoped>

</style>
