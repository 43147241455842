<template>
  <section class="uk-section">
    <div class="uk-container">
      <PermanentDocument
          v-if="service"
          class="uk-margin"
          collection-name="texts"
          document-name="news-header"
      ></PermanentDocument>
      <DocumentColumnLayout
          v-if="service"
          dataType="MarkdownContent"
          :defaultDocumentContent="defaultDocumentContent"
          collection-name="news"
          create-button-label="Add News Item"
          no-documents-type-label="News"
          :isInverted="false"
      ></DocumentColumnLayout>
      <vLoading
          v-else
          resource-name="News Services"
      ></vLoading>
    </div>
  </section>
</template>

<script>
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout"
import PermanentDocument from "@/components/service/document/md/PermanentDocument";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "News",
  components: {
    DocumentColumnLayout,
    PermanentDocument,
  },
  setup() {
    const store = useStore()
    const service = computed(() => store.state.service[store.state.serviceName])

    const defaultDocumentContent = `## News Item

details here...`

    return {
      defaultDocumentContent,
      service,
    }
  }
}
</script>

<style scoped>

</style>
