<template>
  <div
      id="auth"
      class="flex-c center-v center-h"
  >
    <div class="tabs is-toggle is-toggle-rounded">
      <ul>
        <li
            class="is-active"
            :class="{'is-danger': activationError, 'is-success': validationResolved}"
        >
          <a>
            <span class="icon is-large">
              <IconCardAccountDetails/>
            </span>
            <span>Register</span>
          </a>
        </li>
      </ul>
    </div>

    <form
        @submit.prevent
        id="validateAccount"
        ref="validateAccount"
        action="#"
    >

      <article
          v-if="!validationResolved"
          class="message is-warning"
      >
        <div class="message-header">
          <p>Validating your email address</p>
          <button
              class="delete"
              aria-label="delete"
          ></button>
        </div>
        <div class="message-body">
          Let us verify that you brought the matching secret token...
        </div>
      </article>

      <div v-else>
        <article
            v-if="activationError"
            class="message is-danger"
        >
          <div class="message-header">
            <p>Activation Failed</p>
            <button
                class="delete"
                aria-label="delete"
            ></button>
          </div>
          <div
              class="message-body"
              v-html="activationError.message"
          ></div>
        </article>
      </div>
    </form>
  </div>
</template>

<script>
import {VALIDATE_EMAIL} from '@/store/graph/operations'
import {popup} from "@/components/use/popups/popupMessages"
import tokens from '../../../../../server/services/actions/person/lib/tokens'

const {tokenName} = tokens.validateEmail

export default {
  name: "ValidateEmail",
  props: {
    [tokenName]: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      validationResolved: false,
      activationError: false,
    }
  },
  mounted() {
    this.$store.dispatch(VALIDATE_EMAIL, {
      [tokenName]: this[tokenName]
    })
        .then(result => {
          const message = result?.message
          if (message) popup(message)
          return result
        })
        .then(result => {
          this.activationError = result?.message.type === 'error'
          this.validationResolved = !this.activationError
          if (!this.activationError) this.$router.push('/authenticate/login/')
        })
        .catch(() => {
          this.activationError = true
        })
  }
}
</script>

<style scoped>

</style>
