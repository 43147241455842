<template>
  <section class="page uk-section">
    <div class="uk-container">
      <PermanentDocument
          v-if="serviceIsLoaded"
          class="uk-margin"
          :service-name="serviceName"
          :collection-name="collectionName"
          :document-name="documentName"
      ></PermanentDocument>
      <vLoading
          v-else
          :resource-name="`${ serviceName } Service`"
      ></vLoading>
    </div>
  </section>
</template>

<script>
import PermanentDocument from '@/components/service/document/md/PermanentDocument.vue';
import vLoading from '@c/utilities/vLoading.vue'
import { SERVICE_ACRONYM } from '../../../../config';
import {computed} from 'vue'
import {useStore} from "vuex";

export default {
  name: "Page",
  components: {
    PermanentDocument,
    vLoading,
  },
  props: {
    serviceName: {
      type: String,
      default: SERVICE_ACRONYM
    },
    collectionName: {
      type: String,
      default: 'texts'
    },
    documentName: {
      type: String,
      required: true
    },
  },
  setup() {
    const store = useStore()
    const serviceIsLoaded = computed(() => !!store.state.service[SERVICE_ACRONYM])
    return {
      serviceIsLoaded,
    }
  }
}
</script>

<style scoped>

</style>
