<template>
  <div>
    <div class="container">
      <form
          ref="checkIn"
          action="#"
          class="uk-grid-small"
          uk-grid
          @submit.prevent
      >
        <div class="uk-width-1-1@s">
          <div class="uk-margin">
            <div class="uk-inline uk-width-1-1">
              <IconFingerprint class="uk-form-icon uk-icon"></IconFingerprint>
              <input
                  autocomplete="username"
                  class="uk-input"
                  name="username"
                  placeholder="Username"
                  type="text"
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div class="uk-margin">
            <div class="uk-inline uk-width-1-1">
              <IconLock class="uk-form-icon uk-icon"></IconLock>
              <input
                  autocomplete="current-password"
                  class="uk-input"
                  name="passphrase"
                  placeholder="Passphrase"
                  type="password"
              >
            </div>
          </div>
        </div>

        <p>
          <a @click="openForgotPassphrasePage">I forgot my passphrase</a>
        </p>

        <div class="uk-width-1-1 uk-margin-remove">
          <button
              class="uk-button uk-button-primary uk-width-1-1"
              @click="login"
          >
            <span class="icon is-medium">
              <IconFingerprint />
            </span>
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {LOGIN} from "@/store/graph/operations"
import {createPopup} from "@/components/use/popups/popupMessages"
import {handleError} from '@/lib/handleError'
import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "Login",
  props: {
    targetUrl: {
      type: String,
      default: '/'
    }
  },

  setup(props) {
    const targetUrl = computed(() => props.targetUrl
        ?.split('/')
        .map(part => encodeURIComponent(part)) // vue-router bug
        .join('/') ?? '/'
    )
    const router = useRouter()
    const store = useStore()
    const checkIn = ref(null)
    const openForgotPassphrasePage = () => router.push('/authenticate/forgotPassphrase')
    const login = () => {
      localStorage.removeItem('Authorization')
      const form = checkIn.value
      const credentials = {
        username: form.username.value,
        passphrase: form.passphrase.value
      }

      !credentials.username && handleError('username is required to log you in')
      !credentials.passphrase && handleError('your passphrase is required to log in')
      return store.dispatch(LOGIN, credentials)
          .then(result => {
            const message = result?.message
            if (message) createPopup(message)
          })
          .then(() => {
            router.push(targetUrl.value)
          })
    }
    return {
      checkIn,
      openForgotPassphrasePage,
      login
    }
  }
}
</script>

<style scoped>
</style>
