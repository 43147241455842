<template>
  <section
      class="projects uk-section"
  >
    <div class="uk-container">
      <PermanentDocument
          v-if="service"
          class="uk-margin"
          collection-name="texts"
          document-name="projects-header"
      ></PermanentDocument>
      <DocumentColumnLayout
          v-if="service"
          dataType="Project"
          encoding="json"
          :collection-name="collectionName"
          create-button-label="Add Project Description"
          aliasAttributeName="title"
      ></DocumentColumnLayout>
      <vLoading
          v-else
          resource-name="Projects"
      ></vLoading>
    </div>
  </section>
</template>

<script>
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout"
import {useStore} from "vuex";
import {computed} from 'vue'

export default {
  name: "Projects",
  components: {
    DocumentColumnLayout,
  },
  props: {
    collectionName: {
      type: String,
      default: 'projects',
    }
  },
  setup() {
    const store = useStore()
    const service = computed(() => store.state.service[store.state.serviceName])

    return {
      service,
    }
  }
}
</script>

<style scoped>

</style>
