<template>
  <section class="uk-section testimonials">
    <div
        v-if="service"
        class="uk-container">
      <PermanentDocument
          class="uk-margin uk-text-left"
          collection-name="texts"
          document-name="testimonials-header"
      ></PermanentDocument>
      <DocumentColumnLayout
          dataType="Testimonial"
          encoding="json"
          collection-name="testimonials"
          create-button-label="Add Testimonial"
          no-documents-type-label="testimonials"
          :textIsResponsive="true"
          :default-document-content="defaultTestimonial"
          alias-attribute-name="title"
      ></DocumentColumnLayout>
    </div>
  </section>
</template>
<script>
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout";
import PermanentDocument from "@/components/service/document/md/PermanentDocument";
import {useDocumentTemplate} from "@/components/use/serviceAssets/documentEditors/useDocumentTemplate";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "Testimonials",
  components: {
    DocumentColumnLayout,
    PermanentDocument,
  },
  setup() {
    const store = useStore()
    const service = computed(() => store.state.service[store.state.serviceName])
    const {getDocumentTemplate} = useDocumentTemplate()

    const defaultTestimonial = getDocumentTemplate('Testimonial').content

    return {
      defaultTestimonial,
      service
    }
  }
}
</script>
<style scoped>

</style>

