<template>
  <div>
    <div class="container">
      <form
          @submit.prevent
          ref="newAccountEmail"
          action="#"
      >
        <div class="field">
          <div class="control">
            <input
                class="textInput"
                name="oldPassphrase"
                type="password"
                placeholder="Old Passphrase"
                autocomplete="off"
            >
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
                class="textInput"
                name="passphrase"
                type="password"
                placeholder="Passphrase"
                autocomplete="new-password"
            >
          </div>
        </div>

        <div class="field">
          <div class="control">
            <input
                class="textInput"
                name="confirmedPassphrase"
                type="password"
                placeholder="Confirm Passphrase"
                autocomplete="new-password"
            >
          </div>
        </div>


        <div class="action field">
          <div class="control">
            <div class="flex-c center">
              <button
                  @click="changePassphrase"
                  class="button primary is-large"
              >
              <span class="icon is-medium">
                <IconLockCheck/>
              </span>
                <span>
                Change Passphrase
              </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import * as actions from "../../../store/graph/operations"

export default {
  name: "UpdateAccountPassphrase",
  methods: {
    changePassphrase() {
      const form = this.$refs.newAccountEmail
      const credentials = {
        oldPassphrase: form.oldPassphrase.value,
        passphrase: form.passphrase.value,
        confirmedPassphrase: form.confirmedPassphrase.value,
      }

      const missingOldPassphrase = !credentials.oldPassphrase
      const missingPassphrase = !credentials.passphrase
      const missingConfirmed = !credentials.confirmedPassphrase
      const confirmedNotMatching = credentials.passphrase !== credentials.confirmedPassphrase
      const sameChange = credentials.oldPassphrase === credentials.passphrase
      if (missingOldPassphrase) return this.popInfo('You need to enter your old passphrase')
      else if (missingPassphrase) return this.popInfo('You need to enter new passphrase')
      else if (missingConfirmed) return this.popInfo('You need to confirm your new passphrase')
      else if (confirmedNotMatching) return this.popWarning('Your confirmation does not match')
      else if (sameChange) return this.popWarning('Your old and new passphrases cannot be the same')

      this.$store.dispatch(actions.PUT_ACCOUNT_PASSPHRASE, credentials)
          .then(() => {
            this.popSuccess('Successfully updated your passphrase')
          })
    }
  }
}
</script>

<style scoped>

</style>
