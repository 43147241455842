<template>
  <div class="flex-c center-v center-h">
<div class="container">
      <form
          @submit.prevent
          ref="newAccountEmail"
          action="#"
>
<div class="field">
          <div class="control">
            <input
                class="textInput"
                type="text"
                name="newEmail"
                placeholder="new email address"
                autocomplete="email"
            >
          </div>
        </div>

        <div class="action field">
          <div class="control">
            <div class="flex-c center">
              <button
                  @click="updateEmail"
                  class="button primary is-large"
>
                <span class="icon is-medium">
                  <IconLockCheck />
                </span>
                <span>
                  Set new account email
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
</div>
    <div class="field">
      <div class="control">
      </div>
    </div>
</div>
</template>

<script>
  import * as actions from "../../../store/graph/operations"
  import { popup }    from "@/components/use/popups/popupMessages"

  export default {
    name: "ChangeEmail",
    methods: {
      updateEmail() {
        const form = this.$refs.newAccountEmail
        const credentials = {
          newEmail: form.newEmail.value,
          username: this.user.username,
        }
        const missingEmail = !credentials.newEmail
        if (missingEmail) return this.popWarning('a new email address is required to change it')
        this.$store.dispatch(actions.PUT_ACCOUNT_EMAIL, credentials)
          .then(result => {
            const message = result?.message
            if (message) popup(message)
          })
          .catch(err => this.popError('Server error when requesting email change: ' + err.message))
      }
    }
  }
</script>

<style scoped>
  .column {
    text-align: center;
    border: aqua;
    border-width: thick;
    border-style: groove;
  }
  input {
    width: 100%;
  }
</style>
