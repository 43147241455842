<template>
  <LLeftDrawerRow v-if="publication" class="publication" uk-height-match>
    <template #left>nuttin'</template>
    <template #body>
      <div class="cardFrame">
        <div class="publicationCard uk-card uk-card-default">
          <div class="uk-card-media-left">
            <div class="portrait frame">
              <CloudPrint :size="146" :fillColor="PRIMARY_BACKGROUND_COLOR"></CloudPrint>
            </div>
          </div>
          <div class="uk-card-body uk-text-left uk-padding-remove-left">
            <MarkdownContent :collection-id="collectionId" :document-id="publication.repoId"></MarkdownContent>
          </div>
        </div>
      </div>
    </template>
  </LLeftDrawerRow>
</template>
<script>
import MarkdownContent from "../../service/document/md/MarkdownContent.vue";
import CloudPrint from "../../icons/CloudPrint.vue";
import LLeftDrawerRow from "@/components/layout/LLeftDrawerRow";
import {usePermissions} from "@/components/use/usePermissions";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {computed, watch} from "vue";
import {useStore} from "vuex";
import {useCollection} from "../../use/serviceAssets/useCollection";
import {PRIMARY_BACKGROUND_COLOR} from '../../../../../config'

export default {
  name: "Publication",
  components: {
    CloudPrint,
    LLeftDrawerRow,
    MarkdownContent,
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const store = useStore()
    const {loadCollection} = useCollection()
    const {canEdit} = usePermissions(props)
    const {deleteVersion} = useVersion()
    const service = computed(() => store.state.service[store.state.serviceName])
    const collection = computed(() => Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const publication = computed(() => Object.values(store.state.document).filter(d => d.repoId === props.documentId)?.[0])
    const collectionIsLoaded = computed(() => collection.value?.repoId)

    watch(service, service => {
      if (service && !collectionIsLoaded.value) loadCollection({collectionName: 'publications'})
    }, {immediate: true})

    return {
      canEdit,
      collection,
      collectionIsLoaded,
      deleteVersion,
      publication,
      service,
      PRIMARY_BACKGROUND_COLOR,
    }
  }
}
</script>
<style scoped lang="stylus">
.publication
  width 100%

  .settings
    background-color #363636

  .cardFrame
    width 100% !important
    display flex
    align-items center
    justify-content center

    .publicationCard
      max-width 600px
      display grid
      grid-template-columns 200px 1fr

      .portrait
        width 200px
        height 240px
        overflow hidden

        *
          min-height 100%
          display flex
          justify-content center
          align-items center


    .deleteButton
      float right

    .title
    .affiliation
    .focus
    .role
      font-size 80%

    .name
      font-size 110%
      font-weight lighter

    .role
    .focus
      font-style italic

    .title
    .affiliation
      font-weight bold

    hr::after
      width 100%

</style>
