<template>
  <div class="partners">
    <AAULogo v-show="isPartner('AAU')"></AAULogo>
    <AULogo v-show="isPartner('AU')"></AULogo>
    <SDULogo v-show="isPartner('SDU')"></SDULogo>
    <DeiCLogo v-show="isPartner('Deic')"></DeiCLogo>
  </div>
</template>

<script>
import AAULogo from "@/components/logos/AAULogo";
import AULogo from "@/components/logos/AULogo";
import DeiCLogo from "@/components/logos/DeiCLogo";
import SDULogo from "@/components/logos/SDULogo";

export default {
  name: "Partners",
  components: {
    AAULogo,
    AULogo,
    DeiCLogo,
    SDULogo,
  },
  props: {
    partners: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const isPartner = name => props.partners
        .map(p => p.toLowerCase())
        .includes(name.toLowerCase())
    return {
      isPartner,
    }
  }
}
</script>

<style scoped lang="stylus">
  .partners
    display flex
    align-items center
    justify-content center

    *
      margin 0 3%
      width: 15%

@media (min-width:1130px)
  .partners > *
    margin 0 34px
    width 150px

</style>
