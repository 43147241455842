<template>
  <div class="uk-position-center splash-shadow">
    <div class="container">
      <form
          @submit.prevent
          class="uk-grid-small"
          uk-grid
          ref="resetPassphrase"
          autocomplete="off"
          action="#"
      >
        <template
            v-if="showWaitingForValidationMessage"
        >
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <div class="uk-alert uk-alert-warning">
                <h3 class="uk-header">
                  <p>Reset Passphrase</p>
                </h3>
                <div class="message-body">
                  Let us check your reset passphrase token...
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="uk-width-1-1@s">
            <div class="uk-margin">
              <div
                  v-if="showInvalidTokenMessage"
                  class="uk-alert uk-alert-danger"
              >
                <h3 class="header">
                  Invalid or Expired Reset Passphrase Token
                </h3>
                <div class="message-body">
                  <p>
                    You got here with an invalid or expired reset passphrase token. It cannot enable you to set a new
                    passphrase.
                  </p>
                  <button class="uk-button uk-width-1-1@s">
                    <router-link to="/authenticate/forgotPassphrase">
                      Request a new token
                    </router-link>
                  </button>
                </div>
              </div>

              <div
                  v-else
                  class="uk-alert"
              >
                <h3 class="header">
                  Set a new passphrase
                </h3>
                <p class="message-body">
                  Set your new passphrase:
                </p>

                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                      <IconLock class="uk-form-icon uk-icon"></IconLock>
                      <input
                          class="uk-input"
                          name="passphrase"
                          type="password"
                          placeholder="Your new passphrase"
                          autocomplete="new-password"
                      >
                    </div>
                  </div>
                </div>

                <div class="uk-width-1-1@s">
                  <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                      <IconLockCheck class="uk-form-icon uk-icon"></IconLockCheck>
                      <input
                          class="uk-input"
                          name="confirmedPassphrase"
                          type="password"
                          placeholder="Repeat it here"
                          autocomplete="new-password"
                      >
                    </div>
                  </div>
                </div>

                <div class="uk-width-1-1">
                  <button
                      class="uk-button uk-button-primary uk-width-1-1"
                      @click="resetPassphrase"
                  >
                    Save my new passphrase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import * as actions from '../../../store/graph/operations'
import tokens from '../../../../../server/services/actions/person/lib/tokens'
import {popup} from "@/components/use/popups/popupMessages"

const {tokenName} = tokens.resetPassphrase

export default {
  name: "ResetPassphrase",
  props: {
    [tokenName]: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tokenIsValid: false,
      tokenValidationDone: true
    }
  },
  computed: {
    username() {
      return this.$store.state?.user?.username
    },
    showWaitingForValidationMessage() {
      return !this.tokenValidationDone
    },
    showInvalidTokenMessage() {
      return !this.tokenIsValid
    }
  },
  mounted() {
    this.$store.dispatch(actions.VALIDATE_RESET_PASSPHRASE_TOKEN, {
      [tokenName]: this[tokenName]
    })
        .then(result => {
          const message = result?.message
          if (message) popup(message)
          return result
        })
        .then(result => {
          this.tokenValidationDone = true
          const isSuccess = !!result.message.type !== 'error'
          this.tokenIsValid = isSuccess
        })
        .catch(err => {
          this.popError(err.message, 'Server Error')
        })
  },
  methods: {
    resetPassphrase() {
      const form = this.$refs.resetPassphrase
      const credentials = {
        passphrase: form.passphrase.value,
        confirmedPassphrase: form.confirmedPassphrase.value,
      }

      const missingPassphrase = !credentials.passphrase
      if (missingPassphrase) return this.popInfo('please enter your new passphrase to set it')

      const missingConfirmedPassphrase = !credentials.confirmedPassphrase
      if (missingConfirmedPassphrase) return this.popInfo('please repeat your new passphrase to reset it')

      const passphrasesAreNotMatching = credentials.passphrase !== credentials.confirmedPassphrase
      if (passphrasesAreNotMatching) return this.popWarning('your passwords do not match')

      const payload = {
        passphrase: credentials.passphrase,
        [tokens.resetPassphrase.tokenName]: this[tokens.resetPassphrase.tokenName]
      }

      this.$store.dispatch(actions.PUT_NEW_PASSPHRASE, payload)
          .then(result => {
            const message = result?.message
            if (message) popup(message)
            return result
          })
          .then(result => {
            if (result?.message.type !== 'error') {
              this.$router.push('/enter')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
