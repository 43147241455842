<template>
  <LLeftDrawerRow
      v-if="person"
      class="person"
      :show-drawer="showImageDetails"
      uk-height-match
  >
    <template
        #drawer
    >
      <div class="uk-card-default uk-padding-small uk-background-muted uk-height-1-1 settings">
        <ZoomableImageSettings
            v-if="person"
            :document-name="documentId + 'Image'"
            :create-if-not-exists="true"
        ></ZoomableImageSettings>
      </div>
    </template>
    <template #body>

      <div class="personCard uk-card-default"
           :class="{'uk-card-small': removeBodyPaddingLeft, 'uk-card': !removeBodyPaddingLeft}"
      >
        <div class="uk-card-media-left uk-padding-remove-left">
          <div class="portrait frame">
            <ZoomableImage
                :document-name="documentId + 'Image'"
                @click="toggleImageEditor"
            ></ZoomableImage>
          </div>

        </div>
        <div
            class="uk-card-body uk-text-left"
            :class="{'uk-padding-remove-left': removeBodyPaddingLeft}"
        >
          <div
              v-if="canEdit"
              class="deleteButton"
          >
            <DeleteDocumentDialog @delete-document-confirmed="onDelete"></DeleteDocumentDialog>
          </div>
          <EditableDiv
              class="title"
              :isEditable="canEdit"
              placeholder="Title"
              :value="person.title"
              @change="value => updateAttribute('title', value)"
          ></EditableDiv>
          <EditableDiv
              class="name"
              :isEditable="canEdit"
              placeholder="Name"
              :value="person.name"
              default-value="Unnamed Person"
              @change="value => updateAttribute('name', value)"
          ></EditableDiv>
          <EditableDiv
              class="role"
              :isEditable="canEdit"
              :value="person.role"
              placeholder="Role"
              @change="value => updateAttribute('role', value)"
          ></EditableDiv>
          <hr class="uk-divider-small">
          <EditableDiv
              class="affiliation"
              :isEditable="canEdit"
              :value="person.affiliation"
              placeholder="Affiliation"
              @change="value => updateAttribute('affiliation', value)"
          ></EditableDiv>
          <EditableDiv
              class="contact"
              :isEditable="canEdit"
              :value="person.contact"
              placeholder="Contact"
              @change="value => updateAttribute('contact', value)"
          ></EditableDiv>
        </div>
      </div>
    </template>
  </LLeftDrawerRow>
</template>
<script>
import EditableDiv from "@/components/utilities/EditableDiv";
import LLeftDrawerRow from "@/components/layout/LLeftDrawerRow";
import ZoomableImageSettings from "@/components/image/zoomableImage/ZoomableImageSettings";
import ZoomableImage from "@/components/image/zoomableImage/ZoomableImage";
import DeleteDocumentDialog from '@/components/service/document/DeleteDocumentDialog'
import {usePermissions} from "@/components/use/usePermissions";
import {useVersion} from "@/components/use/serviceAssets/useVersion";
import {ref} from "vue";
import {usePerson} from "@/components/use/serviceAssets/documentEditors/Person";
import {useStore} from "vuex";

export default {
  name: "PersonCard",
  components: {
    EditableDiv,
    DeleteDocumentDialog,
    LLeftDrawerRow,
    ZoomableImage,
    ZoomableImageSettings,
  },
  props: {
    collectionId: {
      type: String,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    removeBodyPaddingLeft: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const { canEdit } = usePermissions()
    const { person } = usePerson(props)
    const { deleteVersion, patchVersionPayloadAttribute } = useVersion()

    const showImageDetails = ref(false)
    const toggleImageEditor = () => showImageDetails.value = !showImageDetails.value && canEdit.value

    const updateAttribute = (attributeName, value) => patchVersionPayloadAttribute({
      serviceId: store.state.service[store.state.serviceName].repoId,
      collectionId: props.collectionId,
      documentId: props.documentId,
      attributeName,
      value
    })

    const onDelete = () => {
      deleteVersion({
        serviceId: store.state.serviceName,
        collectionId: props.collectionId,
        documentId: props.documentId,
      })
    }

    return {
      canEdit,
      onDelete,
      person,
      showImageDetails,
      toggleImageEditor,
      updateAttribute,
    }
  }
}
</script>
<style scoped lang="stylus">
.person
  max-width 600px

  .settings
    background-color #363636

  .personCard
    min-width 500px
    display grid
    grid-template-columns 225px 1fr

    .portrait
      width 240px
      height 240px
      overflow hidden

    .deleteButton
      float right

    .title
    .affiliation
    .focus
    .role
      font-size 80%

    .name
      font-size 110%
      font-weight lighter

    .role
    .focus
      font-style italic

    .title
    .affiliation
      font-weight bold

    hr::after
      width 100%
    //hr
    //  margin .5rem
/*.verticallyCentered {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*}*/
</style>
