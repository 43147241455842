<template>
  <section class="uk-section">
    <div
        v-if="service"
        class="uk-container">
      <PermanentDocument
          class="uk-margin uk-text-left"
          collection-name="texts"
          document-name="resources-header"
      ></PermanentDocument>
      <DocumentColumnLayout
          dataType="Resource"
          encoding="json"
          collection-name="resources"
          create-button-label="Add Resource"
          no-documents-type-label="resources"
          alias-attribute-name="title"
      ></DocumentColumnLayout>
    </div>
  </section>
</template>
<script>
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout";
import PermanentDocument from "@/components/service/document/md/PermanentDocument";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "Resources",
  components: {
    DocumentColumnLayout,
    PermanentDocument,
  },
  setup() {
    const store = useStore()
    const service = computed(() => store.state.service[store.state.serviceName])

    return {
      service
    }
  }
}
</script>
<style scoped>

</style>

