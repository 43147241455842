

<template>
  <div
id="auth"
class="uk-position-center splash-shadow"
>
    <div>
      <ul
class="uk-child-width-expand"
uk-tab
>
        <li
            @click="openLoginPage"
            class="uk-active"
        >
<a>Login</a>
<!--</li>-->
<!--        <li-->
<!--          @click="openRegisterPage"-->
<!--        >-->
<!--<a>Register</a>-->
</li>
      </ul>
    </div>

    <register
      v-if="activePage === 'register'"
    ></register>
    <requestPassphraseReset
      v-else-if="activePage === 'forgotPassphrase'"
    ></requestPassphraseReset>
    <login
      v-else
      :target-url="nextUrl"
    ></login>
</div>
</template>

<script>
import Login                from "@/components/site/authentication/Login"
import Register             from "@/components/site/authentication/Register"
import RequestPassphraseReset from "@/components/site/authentication/RequestPassphraseReset"
import {useRouter} from "vue-router";
import {computed} from "vue";
  export default {
    name: "Authentication",
    components: {
      Login,
      Register,
      RequestPassphraseReset,
    },
    props: {
      activePage: {
        type: String,
        default: 'login'
      },
      targetUrl: {
        type: String,
        default: 'enter'
      },
    },
    setup(props) {
      const router = useRouter()
      const nextUrl = computed(() => `${ props.targetUrl.startsWith('/') ? '' : '/' }${ props.targetUrl }`)
      const openLoginPage = () => props.activePage !== 'login' && router.push(`/authenticate/login${nextUrl.value}`)
      const openRegisterPage = () => props.activePage !== 'register' && router.push(`/authenticate/register${nextUrl.value}`)
      return {
        nextUrl,
        openLoginPage,
        openRegisterPage
      }
    }
  }
</script>

<style>
  #auth .tabs li.is-active a {
    text-decoration: none;
    color: white;
  }
  .is-dark #auth .tabs li.is-active a {
    color: black;
  }
  #auth {
    z-index: 999;
    width: min(30rem, 100%);
  }
  #auth button {
    margin-top: 1.5rem;
  }
</style>
