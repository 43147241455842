<template>
  <section
      class="uk-section centerServices"
  >
    <div class="uk-container">
      <PermanentDocument
          v-if="service"
          class="uk-margin"
          collection-name="texts"
          document-name="services-header"
      ></PermanentDocument>
      <DocumentColumnLayout
          v-if="service"
          dataType="CenterService"
          encoding="json"
          collection-name="centerServices"
          create-button-label="Add Service Description"
          :default-document-content="defaultService"
          aliasAttributeName="title"
      ></DocumentColumnLayout>
      <vLoading
          v-else
          resource-name="Center Services"
      ></vLoading>
    </div>
  </section>
</template>

<script>
import DocumentColumnLayout from "@/components/service/collection/DocumentColumnLayout"
import {useDocumentTemplate} from "@/components/use/serviceAssets/documentEditors/useDocumentTemplate";
import {useStore} from "vuex";
import {computed} from 'vue'

export default {
  name: "Services",
  components: {
    DocumentColumnLayout,
  },
  props: {
    collectionName: {
      type: String,
      default: 'services',
    }
  },
  setup() {
    const store = useStore()
    const {getDocumentTemplate} = useDocumentTemplate()
    const defaultService = getDocumentTemplate('CenterService').content

    const service = computed(() => store.state.service[store.state.serviceName])

    return {
      defaultService,
      service,
    }
  }
}
</script>

<style scoped>

</style>
