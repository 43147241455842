<template>
  <section class="uk-section">
    <div class="uk-container">
      <LSquareLeftRow>
        <template #square>
          <Logo :shadow="true">
            <CenterLogo></CenterLogo>
          </Logo>
        </template>
        <template #body>
          <PermanentDocument collection-name="texts" document-name="about-header"></PermanentDocument>
        </template>
      </LSquareLeftRow>
      <People></People>
    </div>
  </section>
</template>

<script>
import CenterLogo from "@/components/site/CenterLogo";
import Logo from "@/components/site/Logo";
import LSquareLeftRow from "@/components/layout/LSquareLeftRow";
import People from "@/components/site/about/People";
import PermanentDocument from "@/components/service/document/md/PermanentDocument";

export default {
  name: "About",
  components: {
    CenterLogo,
    Logo,
    LSquareLeftRow,
    People,
    PermanentDocument,
  },
  props: {
    collectionName: {
      type: String,
      default: 'person',
    },
  },
}
</script>

<style scoped>
</style>
