import { useStore }                        from 'vuex'
import { DELETE_COMPONENT_CONFIGURATION, } from '@/store/graph/operations'
import { handleError }                     from '@/lib/handleError'
import { useDocument }                     from '@/components/use/serviceAssets/useDocument'
import { capitalize }                      from '../../../../../lib/stringFunctions'
import { useVersion }                      from '@/components/use/serviceAssets/useVersion'
import {computed} from "vue";
import {decode} from "@c/service/document/lib/parse";

export const useComponentConfiguration = (props) => {
  const store = useStore()
  const { loadDocument } = useDocument(props)
  const { patchVersionPayloadAttribute } = useVersion()

  const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
  const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))
  const configuration = computed(() => {
    let payload
    if (document.value.repoId) payload = decode(document.value.content, document.value.encoding)
    return payload
  })

  const loadComponentConfiguration = dataType => new Promise((resolve, reject) => {
    dataType = capitalize(dataType)
    loadDocument({
      createIfNotExists: props.createIfNotExists ?? false,
      collectionName: dataType,
      documentName: props.documentName,
      documentId: props.documentId,
      dataType,
    }, 'useComponentConfiguration')
        .catch(err => {
          handleError(`Could not load configuration. ${ err.message ?? JSON.stringify(err) }`)
          reject(`Could not load configuration. ${ err.message ?? JSON.stringify(err) }`)
        })
  })

  const patchConfiguration = (attributeName, value) => {
    if (!document.value) return handleError(`updateConfiguration error: configuration "${ props.collectionName }" is not loaded`)
    if (!attributeName) return handleError(`updateConfiguration error: attributeName is required`)

    patchVersionPayloadAttribute({
      serviceId: store.state.serviceName,
      collectionId: collection.value.repoId,
      documentId: document.value.repoId,
      attributeName,
      value
    })
        .catch(err => {
          console.log(122, err)
          // handleError(`Could not update component configuration attribute. ${ err.message ?? JSON.stringify(err) }`)
        })
  }

  const deleteComponentConfiguration = () => {
    if (!document.value) return handleError(`deleteComponentConfiguration error: configuration "${ props.collectionName }" is not loaded`)
    store.dispatch(DELETE_COMPONENT_CONFIGURATION, { collectionName: props.collectionName })
        .catch(err => {
          console.log(121, err)
          // handleError(`Could not delete component configuration. Server error: ${ err.message ?? JSON.stringify(err) }`)
        })
  }

  return {
    configuration,
    deleteConfiguration: deleteComponentConfiguration,
    loadComponentConfiguration,
    patchConfiguration,
  }
}
