<template>
  <section class="people">
    <div v-if="serviceIsLoaded">
      <PermanentDocument
          class="uk-margin"
          collection-name="texts"
          document-name="people-page-header"
      ></PermanentDocument>
      <section class="documentColumn">
        <div v-if="collectionIsLoaded">
          <div v-if="canEdit" class="uk-button uk-width-1-1 uk-background-primary">
            <CreateCollectionDocument
                :collection-id="collection.repoId"
                :document-encoding="encoding"
                :name-is-required="false"
                :default-document-content="defaultPerson"
                :dataType="dataType"
                create-button-label="Add Person"
            ></CreateCollectionDocument>
          </div>
          <div class="uk-padding-remove-vertical" v-if="people.length">
            <div class="uk-container uk-padding-small uk-padding-remove-horizontal">
              <div v-if="canEdit">
                <SortableDocuments
                    :collection-id="collection.repoId"
                    aliasAttributeName="name"
                ></SortableDocuments>
              </div>
              <div class="documents">
                <LTwoColumns>
                  <template #items>
                    <TransitionGroup>
                      <DocumentLayout
                          v-for="(person, index) in people"
                          :key="index"
                          :collection-id="collection.repoId"
                          :document-id="person.repoId"
                          :document-name="person.name"
                          :removeBodyPaddingLeft="removeBodyPaddingLeft"
                      ></DocumentLayout>
                    </TransitionGroup>
                  </template>
                </LTwoColumns>
              </div>
            </div>
          </div>
          <div v-else class="uk-placeholder">No people here yet</div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import {decapitalize} from "../../../../../lib/stringFunctions";
import {useCollection} from '@/components/use/serviceAssets/useCollection'
import {usePermissions} from "@/components/use/usePermissions";
import {useDocumentTemplate} from "@/components/use/serviceAssets/documentEditors/useDocumentTemplate";
import {computed, watch} from "vue";
import PermanentDocument from "@/components/service/document/md/PermanentDocument";
import CreateCollectionDocument from "@/components/service/document/CreateCollectionDocument";
import SortableDocuments from "../../service/collection/sortable/SortableDocuments.vue";
import LTwoColumns from "../../layout/LTwoColumns.vue";
import DocumentLayout from "../../service/document/DocumentLayout.vue";
import {useStore} from "vuex";

export default {
  name: "People",
  components: {
    CreateCollectionDocument,
    DocumentLayout,
    LTwoColumns,
    PermanentDocument,
    SortableDocuments,
  },
  props: {
    collectionName: {
      type: String,
      default: 'person',
      validator: value => value === 'person'
    },
    removeBodyPaddingLeft: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const store = useStore()
    const {getDocumentTemplate} = useDocumentTemplate()
    const {loadCollection} = useCollection()
    const {canEdit} = usePermissions()
    const dataType = 'PersonCard'
    const collection = computed(() => Object.values(store.state.collection).find(c => c.name === props.collectionName))
    const people = computed(() => {
      let people = []
      if (collection.value?.repoId) {
        people = Object.values(store.state.document).filter(d => d.dataType === dataType && d.parentRepoId === collection.value.repoId)
      }
      const sequence = collection.value?.documentSequence
      if (sequence?.length) people = sequence
          .map(id => store.state.document[id])
          .filter(item => item)

      return people
    })
    const collectionIsLoaded = computed(() => collection.value?.repoId)
    const defaultPerson = getDocumentTemplate('Person').content

    const service = computed(() => store.state.service[store.state.serviceName])
    const serviceIsLoaded = computed(() => !!service.value)

    watch(service, serviceIsLoaded => {
      if (serviceIsLoaded) loadCollection({collectionName: 'person'})
    }, {immediate: true})

    return {
      dataType,
      collectionIsLoaded,
      defaultPerson,
      encoding: "json",
      serviceIsLoaded,
      canEdit,
      collection,
      people,
      assetAlias: decapitalize(dataType),
    }
  }
}
</script>

<style scoped>
</style>
