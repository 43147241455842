<template>
  <form
      @submit.prevent
      id="changeEmail"
      ref="changeEmail"
      action="#"
  >
    <article
        v-if="!validationResolved"
        class="message is-warning"
    >
      <div class="message-header">
        <p>Checking confirmation of unlinking your old email</p>
        <button
            class="delete"
            aria-label="delete"
        ></button>
      </div>
      <div class="message-body">
        Let us check your confirmation token for abandoning your old email address...
      </div>
    </article>

    <div v-else>
      <article
          v-if="activationError"
          class="message is-danger"
      >
        <div class="message-header">
          <p>Confirmation Failed</p>
          <button
              class="delete"
              aria-label="delete"
          ></button>
        </div>
        <div class="message-body">
          Your link was probably broken or you succeeded earlier. Please send another request to change if that is not
          the case.
        </div>
      </article>
    </div>
  </form>
</template>

<script>
import tokens from '../../../../../server/services/actions/person/lib/tokens'
import {popup} from "@/components/use/popups/popupMessages"
import {CONFIRM_CHANGING_EMAIL} from "@/store/graph/operations"

const {tokenName} = tokens.abandonEmail

export default {
  name: "ConfirmChangeEmail",
  props: {
    [tokenName]: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      validationResolved: false,
      activationError: false,
    }
  },
  mounted() {
    this.$store.dispatch(CONFIRM_CHANGING_EMAIL, {
      [tokenName]: this[tokenName]
    })
        .then(result => {
          const message = result?.message
          if (message) popup(result.message)
        })
        .catch(() => {
          this.activationError = true
        })
        .finally(() => {
          this.validationResolved = true
        })
  }
}
</script>

<style scoped>

</style>
