<template>
  <div class="container">
    <form
        @submit.prevent
        class="uk-grid-small"
        uk-grid
        ref="reset"
        action="#"
    >
      <div class="uk-width-1-1@s">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <IconEmail class="uk-form-icon uk-icon"></IconEmail>
            <input
                class="uk-input"
                type="text"
                name="email"
                placeholder="Email"
                autocomplete="email"
            >
          </div>
        </div>
      </div>

      <div class="uk-width-1-1@s">
        <div class="uk-margin">
          <button
              @click="requestPassphraseReset"
              class="uk-button uk-button-primary uk-width-1-1@s"
          >
            <span>Email me a link to reset my passphrase</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import IconEmail from '@/components/icons/Email'

import * as actions from "../../../store/graph/operations"
import {popup} from "@/components/use/popups/popupMessages"

export default {
  name: "RequestPassphraseReset",
  components: {
    IconEmail,
  },
  methods: {
    requestPassphraseReset() {
      const form = this.$refs.reset
      const credentials = {
        email: form.email.value
      }

      const missingEmail = !credentials.email
      if (missingEmail) return this.popInfo('your email is required to request a passphrase reset')

      this.$store.dispatch(actions.RESET_PASSWORD, credentials)
          .then(result => {
            const message = result?.message
            message.timeout = 0
            if (message) popup(result.message)
          })
    }
  }
}
</script>

<style scoped>

</style>
