<template>
  <section class="uk-section">
    <div class="uk-container">
      <div class="publications">
        <PermanentDocument
            v-if="service"
            class="uk-margin"
            collection-name="texts"
            document-name="publications-page-header"
        ></PermanentDocument>
        <DocumentColumnLayout
            v-if="service"
            data-type="Publication"
            :defaultDocumentContent="defaultPublication"
            collection-name="publications"
            create-button-label="Add Publication"
            no-documents-type-label="Publications"
        ></DocumentColumnLayout>
      </div>
    </div>
  </section>
</template>
<script>

import PermanentDocument from "../../service/document/md/PermanentDocument.vue"
import DocumentColumnLayout from "../../service/collection/DocumentColumnLayout.vue"
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'Publications',
  components: {
    DocumentColumnLayout,
    PermanentDocument,
  },
  setup() {
    const store = useStore()
    const service = computed(() => store.state.service[store.state.serviceName])
    const defaultPublication = `# New Publication
Nielbo, K.L, Haestrup, F., Enevoldsen, K.C., Vahlstrup, P.B., Baglini, R.B., Roepstorff, A. (2021, February 12). When no news is bad news - Detection of negative events from news media content. arXiv. ![https://doi.org/arXiv:2102.06505](https://doi.org/arXiv:2102.06505)
`
    return {
      defaultPublication,
      service,
    }
  }
}
</script>
