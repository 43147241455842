<template>
  <form
      @submit.prevent
      id="validateEmailChange"
      ref="validateEmailChange"
      action="#"
  >

    <article
        v-if="activationError"
        class="message is-danger"
    >
      <div class="message-header">
        <p>Activation Failed</p>
        <button
            class="delete"
            aria-label="delete"
        ></button>
      </div>
      <div class="message-body">
        Your link was expired or broken. We are sorry for your inconvenience.
      </div>
    </article>

    <article
        v-else
        class="message is-warning"
    >
      <div class="container">
        <div class="message-header">
          <p>Confirming to change your email to the new email address</p>
          <button
              class="delete"
              aria-label="delete"
          ></button>
        </div>
        <div class="message-body">
          To finalize changing your email we need your password as a last ingredient.
        </div>

        <div class="field">
          <div class="control">
            <input
                class="textInput"
                name="passphrase"
                type="password"
                value="qq"
                placeholder="Passphrase"
                autocomplete="current-password"
            >
          </div>
        </div>

        <div class="action field">
          <div class="control">
            <div class="flex-c center">
              <button
                  @click="validateChangedEmail"
                  class="button primary is-large"
              >
            <span class="icon is-medium">
              <IconFingerprint/>
            </span>
                <span>
              Confirm and Finalize
            </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  </form>
</template>

<script>
import {VALIDATE_CHANGED_EMAIL} from '@/store/graph/operations'
import {popError, popSuccess} from "@/components/use/popups/popupMessages"
import tokens from '../../../../../server/services/actions/person/lib/tokens'

const {tokenName} = tokens.validateChangedEmail

export default {
  name: "ValidateChangedEmail",
  props: {
    [tokenName]: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      validationResolved: false,
      activationError: false,
      navigateAfterMS: 10000,
      resolvedAt: 0,
    }
  },
  methods: {
    validateChangedEmail() {
      const form = this.$refs.validateEmailChange
      const credentials = {
        passphrase: form.passphrase.value
      }

      const missingPassphrase = !credentials.passphrase
      if (missingPassphrase) return this.popError('your passphrase is required to finalize changing your email')

      this.$store.dispatch(VALIDATE_CHANGED_EMAIL, {
        [tokenName]: this[tokenName],
        passphrase: credentials.passphrase
      })
          .then(result => {
            this.activationError = result.error
            if (this.activationError) {
              popError(result.error.message)
            } else {
              popSuccess(result)
            }
          })
          .catch(() => {
            this.activationError = true
          })
          .finally(() => {
            this.validationResolved = true
          })
    }
  }
}
</script>

<style scoped>

</style>
